import _react2 from "react";
import _css2 from "./css";
import _BaseView2 from "./BaseView";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.View = View;
exports.default = void 0;

var _react = _interopRequireDefault(_react2);

var _css = _css2;

var _BaseView = _interopRequireDefault(_BaseView2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}
/**
 * UI Primitive with built-in styled CSS support.
 *
 * @param {object} props Component props.
 * @returns React.Component A React component, enhanced with generated styles.
 * @example
 * <View backgroundColor="blue" color="white">Hello</View>
 */


function View(props) {
  var className = props.className,
      cssProp = props.css,
      restProps = _objectWithoutProperties(props, ["className", "css"]);

  var nextClassName = (0, _css.compileToClassName)(props);
  return _react.default.createElement(_BaseView.default, Object.assign({
    className: nextClassName
  }, restProps));
}

var _default = View;
exports.default = _default;
export default exports;