import _is2 from "@itsjonq/is";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "is", {
  enumerable: true,
  get: function get() {
    return _is.is;
  }
});
var _is = _is2;
export default exports;